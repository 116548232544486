@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src:
    url('https://cdn.mybridge.com/assets/font/PretendardJP-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.mybridge.com/assets/font/PretendardJP-Regular.woff')
      format('woff');
}

/* Pretendard Medium */
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src:
    url('https://cdn.mybridge.com/assets/font/PretendardJP-Medium.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.mybridge.com/assets/font/PretendardJP-Medium.woff')
      format('woff');
}

/* Pretendard SemiBold */
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src:
    url('https://cdn.mybridge.com/assets/font/PretendardJP-SemiBold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.mybridge.com/assets/font/PretendardJP-SemiBold.woff')
      format('woff');
}

/* Pretendard SemiBold */
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src:
    url('https://cdn.mybridge.com/assets/font/PretendardJP-Bold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.mybridge.com/assets/font/PretendardJP-Bold.woff')
      format('woff');
}

body {
  margin: 0;
  font-family: "Pretendard", 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN',
    'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  font-family: "Pretendard", 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN',
    'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'Roboto', sans-serif;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --sidebar-width: 282px;
}

@import './nprogress.css';